<template>
  <div>
    <Toolbar></Toolbar>
    <v-container class="mt-10 text-center">
      <p class="mb-2 text-h5 text-sm-h4">¡Uups! Parece que algo salió mal</p>
      <p class="text-body-1 text-sm-h6">Perdona las molestias, ya estamos trabajando para solucionarlo</p>
      <v-img :src="require('../assets/500-internal-server-error-animate.svg')"
             contain
             max-height="350">
      </v-img>
      <v-btn class="mt-5"
             color="#00999a"
             dark
             elevation="0"
             href="/"
             large
             link
             rounded>
        Ir a inicio
      </v-btn>
    </v-container>
    <Footer></Footer>
  </div>
</template>

<script>
const Toolbar = () => import(/* webpackPrefetch: true */ "../components/Toolbar.vue");
const Footer = () => import(/* webpackPrefetch: true */ "@/components/Footer");

export default {
  name: "Error",
  data: () => ({}),
  components: {
    Toolbar,
    Footer
  },
};
</script>

<style scoped>
</style>

